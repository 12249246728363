import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosService from "core/services/httpRequest/AxiosConfig";

const initialState = {
  files: [],
  loading: false,
};

export const getFiles = createAsyncThunk(
  //action type string
  "/GET_FILES",
  // callback function
  async (billingOuid, thunkAPI) => {
    console.log('debug',billingOuid)
    const res = await axiosService.get("/file");


    const billingsfiles = await axiosService.get(
      `/billingAccount/settlement/${billingOuid}`
    );


    const billingsAccountBalance = await axiosService.get(
      `/billingAccountBalance/billingAccountOuid/${billingOuid}`
    );

    let paymentRequest = await axiosService.get(
      `paymentRequest`
    );

    let currentPayment = undefined

    // try{
    //   currentPayment = await axiosService.get(document.ENVIRONNEMENTS.BACK_HOST + "/payment")
    // }
    // catch(e){
    //   console.log(e)
    // }
    
    const contractFiles = res.data.flatMap((v) => {
      return v.characteristics.type === "CONTRACT" ? [v] : [];
    });
    const invoicesFiles = res.data.flatMap((v) => {
      return v.fileName.charAt(0) === "F" ? [v] : [];
    });
    const sepaFiles = res.data.flatMap((v) => {
      return v.characteristics.type === "SEPA_MANDATE" ? [v] : [];
    });
    const echeancierFiles = res.data.flatMap((v) => {
      return v.characteristics.type === "ECHEANCIER" ? [v] : [];
    });

    const invoicesList = [];

    let contracts = thunkAPI.getState().userContractsState.contracts

    billingsfiles?.data?.forEach((v) => {

    //let contract = contracts.find(c => c.ouid === v.characteristics.subscriptionOuid)

    let invoiceBillingAccountBalance = billingsAccountBalance?.data.filter(b => {
      return b.settlementNoteAdviceOuid === v.ouid
    }).sort((v1, v2) => v1.dateTimeCreate - v2.dateTimeCreate).reverse()[0]

    let lastBillingAccountBalance = billingsAccountBalance?.data.filter(b => {
        return b.transactionId === invoiceBillingAccountBalance.transactionId
    }).sort((v1, v2) => v1.letteringDate - v2.letteringDate).reverse()[0]

    let currentPaymentRequests = paymentRequest?.data?.filter(p => p.paymentItems.some(pi => pi.referredTypeOuid === lastBillingAccountBalance.ouid))

    let contract = contracts.find(c => c.billingAccountOuid === v.billingAccountOuid)

    let hasPaymentRequestPending = currentPaymentRequests?.some(p => p.status === "PENDING")

    let hasCurrentlyAPendingPayment = currentPayment?.data.payments.some(p => p.transactionId === lastBillingAccountBalance.transactionId)

    let restToPay = lastBillingAccountBalance.openAmount
      invoicesList.push({
        contractCode: contract?.contractCode,
        billingAccountOuid: v.billingAccountOuid,
        name: v.id,
        ref: v.id,
        state: restToPay > 0 ? "DUE" : "PAID",
        status: restToPay > 0 ? "DUE" : "PAID",
        transactionId: lastBillingAccountBalance.transactionId,
        hasPaymentPlan: lastBillingAccountBalance.paymentPlanOuid !== undefined,
        canPayWithCC: restToPay > 0  && !hasPaymentRequestPending && !hasCurrentlyAPendingPayment,
        billDate: v.billDate,
        echeance: v.paymentDueDate,
        startDateTime: v.startDateTime,
        endDateTime: v.endDateTime,
        taxExcludedAmount: v.taxExcludedAmount,
        taxIncludedAmount: v.taxIncludedAmount,
        openAmount: restToPay,
        characteristics: {
          type: "Factures",
        },
      });
    });

    console.log('invoicesList',invoicesList)

    const newList = [];
    invoicesFiles.forEach((l) => {
      const name = l.fileName.replace(".pdf", "");
      invoicesList.find((o) =>
        o.name === name
          ? newList.push({
              ...o,
              idFile: l.ouid,
              mediaType: l.mediaType,
              fileNamePDF: l.fileName,
            })
          : ""
      );
    });

    const filesList = {
      contract: contractFiles ? contractFiles : "",
      invoices: newList,
      sepa: sepaFiles ? sepaFiles : "",
      echeancier: echeancierFiles ? echeancierFiles : "",
    };

    return filesList;
  },
  //   Add condition is already in the store
  // {
  //   condition: (_, thunkAPI) => {
  //     const data = thunkAPI.getState();
  //     const fetchFiles = data.allFiles.files;

  //     // if (Object.keys(fetchFiles).length > 0) {
  //     //   // Already fetched or in progress, don't need to re-fetch
  //     //   return false;
  //     // }
  //   },
  //}
);

export const filesSlice = createSlice({
  name: "files",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFiles.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFiles.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.files = payload;
      })
      .addCase(getFiles.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default filesSlice.reducer;
