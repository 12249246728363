import { Grid } from "@mui/material";
import Button from "components/atoms/Button/Button";
import ContainerItem from "components/atoms/ContainerItem/ContainerItem";
import BlocDocTelechargement from "components/organisms/BlocDocTelechargement";
import BlocInformationContrat from "components/organisms/BlocInformationContrat";
import BlocInformationsUtiles from "components/organisms/BlocInformationsUtiles";
import BlocInstallation from "components/organisms/BlocInstallation";
import BlocMonOffre from "components/organisms/BlocMonOffre";
import LayoutPage from "components/organisms/Layout/LayoutPage";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { formatHPHC } from "utils";
import { getCalendrierFrnCodeName } from "utils/calendrierFrnCodes";
import { getSsoLink } from "../../core/services/API/SSOZendesk/getLinkSSO";
import "./moncontrat.scss";

const MonContrat = () => {
  const { t } = useTranslation();
  // const dispatch = useDispatch();
  const productSerialNumber = useSelector(
    (state) =>
      state?.userContractsState?.contracts?.find((c) => c?.selected)
        ?.souscription?.productSerialNumber
  );
  const [documents, setDocuments] = useState([
    {
      id: 1,
      title: "Mon justificatif de domicile",
      link: "/",
      fileName: "",
      type: "file"
    },
    {
      id: 2,
      title: "Mes Conditions Particulières de Vente",
      link: "",
      fileName: "",
      type: "file"
    },
    {
      id: 3,
      title: "Mes Conditions Générales de Vente",
      link: "/conditions-generales-de-vente/",
      fileName: "",
      type: "link"
    },
    {
      id: 4,
      title: "Mon mandat de prélèvement SEPA",
      link: "",
      fileName: "",
      type: "file"
    },
  ]);

  const INFO = {
    text: "Pour télécharger mon échéancier et mes factures, rendez-vous dans la rubrique",
    link: "/factures-et-paiements",
    txtLink: "Factures et paiements",
  };

  const [services, setServices] = useState([]);

  const infos = useSelector((state) => state.individualState.infos);
  const contrat = useSelector((state) =>
    state.userContractsState.contracts.find((contrat) => contrat.selected)
  );
  const userSubscribedOffer = useSelector((state) =>
    state.userContractsState.contracts
      .find((contrat) => contrat.selected)
      ?.products?.find((product) => product?.plan)
  );
  const catalog = useSelector((state) => state.userContractsState.catalog);
  const listFiles = useSelector((state) => state.allFiles);
  const billingsInfo = useSelector(
    (state) => state.billingsAccount.billingsAccountInfo
  );

  useEffect(() => {
    // = catalogue options
    // je récupere 13 options du catalogue qui ont cette catégorie, cependant lesquelles dois je afficher ?
    let catalogOptions = catalog?.filter(
      (p) =>
        p.category.name === "OPTION" &&
        !!p.productSpecification.characteristics.find(
          (r) => r.name === "bundleName"
        )
    );
    // console.clear()
    // console.log("*******************", catalogOptions2);
    //
    // let catalogOptions = catalog?.filter((p) =>
    //   _.includes(p.name.split("_"), "OPTION")
    // );

    // = options souscrites
    let optionsSouscrites = contrat?.products?.filter((p) =>
      _.includes(p.name.split("_"), "OPTION")
    ).filter(p => p.status !== "TERMINATED");

    // recherche les options soucrites par name et ajoute un booléen sur cet état
    let output = catalogOptions.map((o) =>
      _.includes(
        optionsSouscrites?.map((x) => x?.name),
        o.name
      )
        ? { ...o, subscribed: true }
        : { ...o, subscribed: false }
    );

    setServices(output);
  }, [contrat, catalog]);

  useEffect(() => {
    if (Object.keys(listFiles.files).length > 0) {
      setDocuments([
        {
          id: 1,
          title: "Mon justificatif de domicile",
          link: "/",
          fileName: "",
        },
        {
          id: 2,
          title: "Mes Conditions Particulières de Vente",
          link: listFiles?.files?.contract.find( (c) => c.characteristics.subscriptionSerialNumber === productSerialNumber)?.ouid,
          fileName: listFiles?.files?.contract.find( (c) => c.characteristics.subscriptionSerialNumber === productSerialNumber)?.fileName,
          type: "file"
        },
        {
          id: 3,
          title: "Mes Conditions Générales de Vente",
          link: "https://www.primeo-energie.fr/conditions-generales-de-vente/",
          fileName: "",
          type: "link"
        },
        {
          id: 4,
          title: "Mon mandat de prélèvement SEPA",
          link: listFiles?.files?.sepa.find( (c) => c.characteristics.subscriptionSerialNumber === productSerialNumber)?.ouid,
          fileName: listFiles?.files?.sepa.find( (c) => c.characteristics.subscriptionSerialNumber === productSerialNumber)?.fileName,
          type: "file"
        },
      ]);
    }
  }, [listFiles, productSerialNumber]);

  return (
    <LayoutPage>
      <div className="container-contrat">
        <ContainerItem container spacing={3.5}>
          <Grid item xs={12} md={8}>
            <BlocInformationContrat
              contratNumber={contrat?.souscription?.productSerialNumber}
              dateStartFourniture={
                contrat?.PDL?.characteristics.activationDate * 1
              }
              dateStartSouscription={contrat?.souscription?.dateTimeCreate * 1}
              // dateStartSouscription={infos?.dateTimeCreate * 1}
              titulaire={infos}
              cotitulaires={infos?.relatedParty}
              adresse={
                infos?.contactMediums
                ?.filter((i) => i?.type === "BILLING_ADDRESS")
              .sort((a, b) => b?.startDateTime - a?.startDateTime)?.[0]?.medium
              }
              modeFacturation={billingsInfo.frequencyBillings}
              modePaiement={"Prélèvement automatique"}
            />
            <BlocInstallation
              pdl={contrat?.PDL?.characteristics?.PRIMARY_RESOURCE}
              adressePDL={{
                numero:
                  contrat?.PDL?.characteristics["adresseInstallation.numRue"],
                rue: contrat?.PDL?.characteristics["adresseInstallation.rue"],
                commune:
                  contrat?.PDL?.characteristics[
                    "adresseInstallation.libelleCommune"
                  ],
                zipCode:
                  contrat?.PDL?.characteristics[
                    "adresseInstallation.codePostal"
                  ],
              }}
              numeroCompteur={
                contrat?.PDL?.characteristics["compteur.numSerie"]
              }
              typeCompteur={contrat?.PDL?.characteristics["compteur.type"]}
            />
            <BlocMonOffre
              offre={userSubscribedOffer?.plan?.productSpecification?.name}
              description={
                userSubscribedOffer?.plan?.productSpecification?.description
              }
              puissance={
                contrat?.PDL?.characteristics["puissanceSouscrite.BASE"]
              }
              optionTarif={getCalendrierFrnCodeName(
                contrat?.PDL?.characteristics["calendrierFournisseur"]
              )}
              optionActive={services.filter((s) => s.subscribed === true)}
              optionDispo={services.filter((s) => s.subscribed === false)}
            />
          </Grid>

          <Grid item xs={12} md={4} className="gridJonction">
            <BlocDocTelechargement listeDocuments={documents} info={INFO} />
            <BlocInformationsUtiles
              heureCreuses={
                getCalendrierFrnCodeName(
                  contrat?.PDL?.characteristics["calendrierFournisseur"]
                )?.isHPHC
                  ? formatHPHC(
                      contrat?.PLD?.characteristics["libellePlageHeuresCreuses"]
                    )
                  : false
              }
              numero={contrat?.PDL?.characteristics["numDepannage"]}
              departement={contrat?.PDL?.characteristics["departement"]}
            />
            <Button
              className="green outlineSmall"
              style={{ margin: "0 auto" }}
              onClick={() =>
                getSsoLink(
                  "https://assistance.primeo-energie.fr/hc/fr/requests/new?ticket_form_id=8129151171869"
                )
              }
            >
              {t("all.resiBtn")}
            </Button>
          </Grid>
        </ContainerItem>
      </div>
    </LayoutPage>
  );
};

export default MonContrat;
