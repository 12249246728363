import React, { useEffect, useState } from "react";
import TableComponent from "../Tables/TableComponent/TableComponent";
import TableResponsive from "../Tables/TableResponsive/TableResponsive";
import BlocContent from "components/molecules/BlocContent/BlocContent";
import Button from "components/atoms/Button/Button";
import CustomDatePicker from "components/atoms/Inputs/DatePicker";
import ComboBox from "components/atoms/Inputs/ComboBox/ComboBox";
import { useTranslation } from "react-i18next";
import "./blocTableauFEP.scss";
import NavLink from "components/atoms/NavLink/NavLink";
import { filterRowsIncoives, filterRowsPayments } from "utils";
import moment from "moment";
import { Box, Modal, Typography } from "@mui/material";

const BlocTableauFEP = ({
  view,
  datasInvoice,
  datasPayment,
  headCellsInvoice,
  headCellsPayment,
  showFilters = true,
  link = "/",
  pagination = true,
  loading = false,
  numberRowLimit,
}) => {
  const { t } = useTranslation();

  const [date, setDate] = useState();
  const [endDate, setEndDate] = useState();
  const [type, setType] = useState();
  const [statut, setStatut] = useState();
  const [selectInvoice, setSelectInvoice] = useState(true);
  const listTypeInvoices = ["Factures", "Avoirs"];
  const listTypePayment = ["Paiement", "Remboursement"];
  const statutType = ["Payée", "En attente"];
  const statutTypePayment = ["Payé", "En attente", "Rejeté", "Annulé"];

  const [datasInvoiceSorting, setDatasInvoiceSorting] = useState(datasInvoice);
  const [datasPaymentSorting, setDatasPaymentSorting] = useState(datasPayment);

  useEffect(() => {
    setDatasInvoiceSorting(datasInvoice);
    setDatasPaymentSorting(datasPayment);
  }, [datasPayment, datasInvoice]);

  const resetFilters = () => {
    setType(undefined);
    setStatut(undefined);
    setDate(undefined);
    setEndDate(undefined);
    setDatasInvoiceSorting(datasInvoice);
    setDatasPaymentSorting(datasPayment);
  };

  const handleClick = (e) => {
    if (e === t("tableauFacture.title")) {
      setSelectInvoice(true);
    } else {
      setSelectInvoice(false);
    }
    resetFilters();
  };

  const handleFilter = () => {
    const checking = !!date || !!endDate || !!type || !!statut;

    if (selectInvoice === true && checking === true) {
      setDatasInvoiceSorting(
        filterRowsIncoives(datasInvoice, date, endDate, type, statut)
      );
    } else if (selectInvoice === false && checking === true) {
      setDatasPaymentSorting(
        filterRowsPayments(datasPayment, date, endDate, type, statut)
      );
    } else {
      setDatasInvoiceSorting(datasInvoice);
      setDatasPaymentSorting(datasPayment);
    }
  };

  return (
    <>
    <BlocContent
      title={t("pages.facturesEtPaiements.fep")}
      variant="h2"
      iconName="Paper"
      iconColor={"white"}
      iconStyle={{ height: "20px", width: "20px" }}
      className="bloc_tableauFEP"
    >
      <div className="selectionTypes">
        <div
          className={`btn_invoice chose_tab ${selectInvoice ? "selected" : ""}`}
          onClick={(e) => handleClick(e.target.textContent)}
        >
          {selectInvoice ? (
            <b>{t("tableauFacture.title")}</b>
          ) : (
            t("tableauFacture.title")
          )}
        </div>
        <div
          className={`btn_payment chose_tab ${!selectInvoice ? "selected" : ""
            }`}
          onClick={(e) => handleClick(e.target.textContent)}
        >
          {!selectInvoice ? (
            <b>{t("tableauPaiement.title")}</b>
          ) : (
            t("tableauPaiement.title")
          )}
        </div>
      </div>

      {showFilters && (
        <div className="filter">
          <div className="titleFilter">{t("tableauFacture.titleFilter")}</div>
          <div className="wrapper_filter">
            <ComboBox
              label="Type"
              value={type}
              list={selectInvoice ? listTypeInvoices : listTypePayment}
              onChange={(e) => {
                setType(e);
              }}
            />

            <CustomDatePicker
              value={date}
              label="Date de début"
              onChange={(e) => setDate(e)}
              limitation={{
                max: endDate ? endDate : undefined,
              }}
            />
            <CustomDatePicker
              value={endDate}
              label="Date de fin"
              onChange={(e) => setEndDate(e)}
              limitation={{
                min: date ? date : undefined,
              }}
            />
            <ComboBox
              label="Statut"
              value={statut}
              list={selectInvoice ? statutType : statutTypePayment}
              onChange={(e) => {
                setStatut(e);
              }}
            />
            <Button className="blue" onClick={handleFilter}>
              Filtrer
            </Button>
            <p
              className="reset"
              onClick={() => resetFilters()}
              style={{
                textDecoration: "underline",
                cursor: "pointer",
                margin: "0 0 0 12px",
              }}
            >
              Effacer
            </p>
          </div>
        </div>
      )}

      {view === "SMALL" && (
        <TableResponsive
          datas={selectInvoice ? datasInvoiceSorting : datasPaymentSorting}
          pagination={pagination}
          numberRowLimit={numberRowLimit}
        />
      )}
      {view === "LARGE" && (
        <TableComponent
          header={selectInvoice ? headCellsInvoice : headCellsPayment}
          datas={selectInvoice ? datasInvoiceSorting : datasPaymentSorting}
          pagination={pagination}
          loading={loading}
          numberRowLimit={numberRowLimit}
        />
      )}

      {!pagination && (
        <div style={{ textAlign: "center", marginTop: "12px" }}>
          <NavLink to={link} className="black" disabled={true}>
            Voir toutes les factures et paiements
          </NavLink>
        </div>
      )}
    </BlocContent>
    </>
  );
};

export default BlocTableauFEP;
