import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosService from "core/services/httpRequest/AxiosConfig";

const initialState = {
  billingsAccountInfo: [],
  loading: false,
};

export const getBillingsAccount = createAsyncThunk(
  //action type string
  "/GET_BILLINGS_ACCOUNT_INFO",
  // callback function
  async (_, thunkAPI) => {
    const res = await axiosService.get("/billingAccount");
    let contracts = thunkAPI.getState().userContractsState.contracts.filter((v) => v.selected);
    let billingAccountOuid = contracts[0].souscription.billingAccountOuid;
    let data = res.data.filter((v) => v.ouid === billingAccountOuid)[0]
    console.log(data);
    const billingsInfo = {
      billingsAccountOuid: data.ouid,
      balance: data.balance,
      frequencyBillings: data.billingCycleSpecification.frequency,
      nextBillingDate: data.billingCycleSpecification.nextBillingDate,
      startBillingDate: data.billingCycleSpecification.startBillingDate,
      frequencyNumber: data.billingCycleSpecification.frequencyNumber,
      frequency: data.billingCycleSpecification.frequency
    };

    return billingsInfo;
  },
  //   Add condition if is already in the store
  {
    condition: (_, thunkAPI) => {
      const data = thunkAPI.getState();
      const fetchBillingsAccount = data.billingsAccount.billingsAccountInfo;

      // if (Object.keys(fetchBillingsAccount).length > 0) {
      //   // Already fetched or in progress, don't need to re-fetch

      //   return false;
      // }
    },
  }
);

export const billingAccountSlice = createSlice({
  name: "billingsAccount",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBillingsAccount.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBillingsAccount.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.billingsAccountInfo = payload;
      })
      .addCase(getBillingsAccount.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default billingAccountSlice.reducer;
