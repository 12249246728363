import React, { useState, useEffect, useCallback, useRef } from "react";
import ContainerItem from "components/atoms/ContainerItem/ContainerItem";
import LayoutPage from "components/organisms/Layout/LayoutPage";
import BlocJeDois from "components/organisms/BlocJeDois";
import BlocTableauFEP from "components/organisms/BlocTableauFEP/BlocTableauFEP";
import BlocModeFacturationFEP from "components/organisms/BlocModeFacturation";
import BlocMoyenPaiementFEP from "components/organisms/BlocMoyenPaiement/BlocMoyenPaiementFEP";
import { Box, Grid, Modal, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./styles/facturesEtPaiements.scss";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentMeans } from "core/services/API/PaymentMeans/paymentMeansSlice";
import moment from "moment";
import { statutPayment, typePayment } from "utils";
import axiosService from "core/services/httpRequest/AxiosConfig";
import { getFiles } from "core/services/API/Files/FilesSlices";

const FacturesEtPaiements = () => {
  const dispatch = useDispatch();

  // Selector
  const priceCharges = useSelector(
    (state) => state.billingsAccount.billingsAccountInfo
  );
  const paymentMeansData = useSelector(
    (state) => state.paymentMeansData.paymentMeans
  );
  const listFiles = useSelector((state) => state.allFiles);
  const listPayments = useSelector((state) => state.payments.paymentRequest);

  const [totalBilling, setTotalBilling] = useState(0);
  const [infoPaymentMeans, setInfoPaymentMeans] = useState();
  const [files, setFiles] = useState([]);
  const [filesPayment, setFilespayment] = useState([]);

  const [view, setView] = useState("LARGE");
  const [screenSize, setScreenSize] = useState(0);
  
  const [open, setOpen] = useState(false);
  const [currentPayment, setCurrentPayment] = useState();

  const productSerialNumber = useSelector(
    (state) =>
      state?.userContractsState?.contracts?.find((c) => c?.selected)
        ?.souscription?.productSerialNumber
  );

  const { t } = useTranslation();

  const stateRef = useRef();

  stateRef.current = files

  const headCellsInvoice = [
    {
      id: "types",
      numeric: false,
      disablePadding: true,
      label: t("tableauFacture.type"),
    },
    {
      id: "montant",
      numeric: true,
      disablePadding: true,
      label: t("tableauFacture.montant"),
    },
    {
      id: "date",
      numeric: false,
      disablePadding: true,
      label: t("tableauFacture.date"),
    },
    {
      id: "reference",
      numeric: false,
      disablePadding: true,
      label: t("tableauFacture.reference"),
    },
    {
      id: "echeance",
      numeric: false,
      disablePadding: true,
      label: t("tableauFacture.echeance"),
    },
    {
      id: "statut",
      numeric: false,
      disablePadding: true,
      label: t("tableauFacture.statut"),
    },
  ];

  const headCellsPayment = [
    {
      id: "types",
      numeric: false,
      disablePadding: true,
      label: t("tableauPaiement.type"),
    },
    {
      id: "montant",
      numeric: true,
      disablePadding: true,
      label: t("tableauPaiement.montant"),
    },
    {
      id: "date",
      numeric: false,
      disablePadding: true,
      label: t("tableauPaiement.date"),
    },
    {
      id: "statut",
      numeric: false,
      disablePadding: true,
      label: t("tableauPaiement.statut"),
    },
  ];

  const detectSize = () => {
    setScreenSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);

    if (screenSize <= 1024 && !!screenSize) {
      setView("SMALL");
    } else {
      setView("LARGE");
    }

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [screenSize]);

  useEffect(() => {
    dispatch(getPaymentMeans());
  }, []);

  useEffect(() => {
    if (priceCharges?.balance && priceCharges?.balance < 0) {
      setTotalBilling(priceCharges?.balance);
    } else setTotalBilling(0);
  }, [priceCharges]);

  useEffect(() => {
    if (paymentMeansData) {
      setInfoPaymentMeans(paymentMeansData);
    }
  }, [paymentMeansData]);

  useEffect(() => {
    if (listFiles) {
      async function setFile() {
        setFiles(await tableInvoices(listFiles));
      }
      setFile();
      console.log("listFiles", listFiles);
    }
    if (listPayments) {
      setFilespayment(tablePayment(listPayments));
    }
  }, [listFiles, listPayments, productSerialNumber]);

  const handlePayment = useCallback((transactionId) => {
    axiosService.post(document.ENVIRONNEMENTS.BACK_HOST + "/payment", {
      billingAccountBalanceTransactionId: transactionId,
    }).then((res) => {
      let invoice = stateRef.current.filter((v) => v.transactionId === transactionId)[0]
      
      console.log(invoice)
      invoice.shouldAllowCCPayment = false

      let newFiles = [...stateRef.current.filter((v) => v.transactionId !== transactionId), invoice]
      newFiles.sort((v1, v2) => {
        return moment(v2.date, "DD/MM/yyyy").diff(moment(v1.date, "DD/MM/yyyy"))
      })
      setFiles(newFiles)

      setOpen(true);
    });
  })

  // Create list invoices
  const tableInvoices = async ({ files }) => {
    const typeFiles = files?.invoices;
    let list = [];

    const paymentRequest = await axiosService.get(
      `paymentRequest`
    );

    typeFiles?.forEach((v, i) => {
      const statutF = v.status === "PAID"
      list?.push({
        transactionId: v.transactionId,
        typeData: "factures",
        billingAccountOuid: v.billingAccountOuid,
        shouldAllowCCPayment: v.canPayWithCC,
        hasPaymentPlan: v.hasPaymentPlan,
        handlePayment: () => handlePayment(v.transactionId, v.billingAccountOuid),
        types: v.characteristics.type,
        montant: (v.taxIncludedAmount / 100)
          ?.toFixed(2)
          ?.toString()
          ?.replace(".", ","),
        date: moment(v.billDate).format("DD/MM/yyyy"),
        reference: v.ref,
        echeance: moment(v.echeance).format("DD/MM/yyyy"),
        statut: statutF,
        telecharger: v.idFile,
        name: v.fileNamePDF,
      });
    });
    list = list.sort((v1, v2) => {
      return moment(v2.date, "DD/MM/yyyy").diff(moment(v1.date, "DD/MM/yyyy"))
    })
    return list;
  };

  // Create list payment

  const tablePayment = (payments) => {
    const list = [];

    payments?.forEach((v, i) => {
      const statutP = statutPayment(v.status);
      list?.push({
        typeData: "paiements",
        types: typePayment(v.type),
        montant: (v.amount / 100)?.toFixed(2)?.toString()?.replace(".", ","),
        date: moment(v.date).format("DD/MM/yyyy"),
        statut: statutP,
      });
    });

    return list;
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    top: '25%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'white',
    padding: '20px',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <LayoutPage>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={style}>
          <svg fill="#000000" width="150px" style={{justifyContent: 'center'}} version="1.1" id="Capa_1"
            viewBox="0 0 75.294 75.294">
            <g>
              <path d="M66.097,12.089h-56.9C4.126,12.089,0,16.215,0,21.286v32.722c0,5.071,4.126,9.197,9.197,9.197h56.9
		c5.071,0,9.197-4.126,9.197-9.197V21.287C75.295,16.215,71.169,12.089,66.097,12.089z M61.603,18.089L37.647,33.523L13.691,18.089
		H61.603z M66.097,57.206h-56.9C7.434,57.206,6,55.771,6,54.009V21.457l29.796,19.16c0.04,0.025,0.083,0.042,0.124,0.065
		c0.043,0.024,0.087,0.047,0.131,0.069c0.231,0.119,0.469,0.215,0.712,0.278c0.025,0.007,0.05,0.01,0.075,0.016
		c0.267,0.063,0.537,0.102,0.807,0.102c0.001,0,0.002,0,0.002,0c0.002,0,0.003,0,0.004,0c0.27,0,0.54-0.038,0.807-0.102
		c0.025-0.006,0.05-0.009,0.075-0.016c0.243-0.063,0.48-0.159,0.712-0.278c0.044-0.022,0.088-0.045,0.131-0.069
		c0.041-0.023,0.084-0.04,0.124-0.065l29.796-19.16v32.551C69.295,55.771,67.86,57.206,66.097,57.206z"/>
            </g>
          </svg>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Un lien de paiement a été envoyé à votre adresse mail.
          </Typography>
          <button onClick={handleClose} style={{marginTop: "20px"}}>Fermer</button>
        </Box>
      </Modal>
      <ContainerItem container spacing={3.5} className="container-fep">
        <Grid item xs={12} sm={6} lg={4}>
          <BlocJeDois price={totalBilling} />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <BlocModeFacturationFEP
            mensualise={priceCharges.frequencyBillings}
            freqency={priceCharges.frequencyNumber}
            nextDateBill={priceCharges.nextBillingDate}
            date={priceCharges.nextBillingDate !== 0 ? moment(priceCharges.nextBillingDate).format("DD/MM/yyyy") : undefined}
          />
        </Grid>

        <Grid item xs={12} lg={4}>
          <BlocMoyenPaiementFEP info={infoPaymentMeans} />
        </Grid>

        <Grid item xs={12}>
          <BlocTableauFEP
            view={view}
            datasInvoice={files}
            datasPayment={filesPayment}
            headCellsInvoice={headCellsInvoice}
            headCellsPayment={headCellsPayment}
            loading={listFiles.loading}
          />
        </Grid>
      </ContainerItem>
    </LayoutPage>
  );
};

export default FacturesEtPaiements;
