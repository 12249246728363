import moment from "moment";

import "moment/locale/fr";

import i18n from "i18next";
import _ from "lodash";
moment.locale("fr");

export function formatErrorEmail(email) {
  if (
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    ) === false
  )
    return i18n.t("error.email");
}

/**
 * @name addDays
 * @description Returns a date depending on date (date) and days (days) you give as args
 * @param {number} days
 *
 * @returns {date} date with days added
 */
export function addDays(days) {
  return moment().transform("00:00:00.000").add(days, "days");
}

export function addDaysBis(days) {
  return moment().transform("23:59:59.000").add(days, "days");
}

/**
 * @name setMinMaxDate
 * @description Returns a date depending on value you give as arg (value) and the mesure of ths value (days or years)
 * @param {number} value
 * @param {string} mesure
 * @returns {date} birthdate
 */
export function setMinMaxDate(value, mesure) {
  switch (mesure) {
    case "year":
      return moment().transform("00:00:00.000").subtract(value, "year");
    case "days":
      return addDaysBis(value);
    case "now":
      return moment().transform("00:00:00.000");

    default:
      return moment().transform("00:00:00.000").subtract(value, "year");
  }
}

/**
 * @name dateIsBetween
 * @description Returns true or error message if age (date) is out min & max range, depending on mesure (year ou days)
 * @param {date} date
 * @param {date} min
 * @param {date} max
 * @param {string} mesure
 * @returns {*} answer
 */
export function dateIsBetween(date, min, max, mesure) {
  return moment(date) >= setMinMaxDate(min, mesure) &&
    moment(date) <= setMinMaxDate(max, mesure)
    ? true
    : false;
}

export function dateIsBetweenNowAnd(date, value, mesure) {
  return moment(date) >= setMinMaxDate("now") &&
    moment(date) <= setMinMaxDate(value, mesure)
    ? true
    : false;
}

export function filterRowsIncoives(datas, startDate, endDate, type, statut) {
  let rows = datas;

  if (type) {
    const newData = rows.filter((v, i) => {
      return v.types === type;
    });
    rows = newData;
  }

  if (startDate && endDate) {
    const newData = rows.filter((v, i) => {
      const s = moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD");
      const e = moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD");
      return moment(v.date, "DD/MM/YYYY").isBetween(s, e, null, []);
    });
    rows = newData;
  }

  if (statut) {
    const newData = rows.filter((v, i) => {
      if (statut === "Payée") {
        return v.statut !== "DUE";
      } else if (statut === "En attente") {
        return v.statut === "DUE";
      }
    });
    rows = newData;
  }

  if (startDate && !endDate) {
    const newData = rows.filter((v, i) => {
      const s = moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD");
      return moment(v.date, "DD/MM/YYYY").isSameOrAfter(s);
    });
    rows = newData;
  }

  if (endDate && !startDate) {
    const newData = rows.filter((v, i) => {
      const e = moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD");

      return moment(v.date, "DD/MM/YYYY").isSameOrBefore(e);
    });
    rows = newData;
  }
  return rows;
}

export function filterRowsPayments(datas, startDate, endDate, type, statut) {
  let rows = datas;

  if (type) {
    const newData = rows.filter((v, i) => {
      return v.types === type;
    });
    rows = newData;
  }

  if (startDate && endDate) {
    const newData = rows.filter((v, i) => {
      const s = moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD");
      const e = moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD");
      return moment(v.date, "DD/MM/YYYY").isBetween(s, e, null, []);
    });
    rows = newData;
  }

  if (statut) {
    const newData = rows.filter((v, i) => {
      return v.statut === statut;
    });
    rows = newData;
  }

  if (startDate && !endDate) {
    const newData = rows.filter((v, i) => {
      const s = moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD");
      return moment(v.date, "DD/MM/YYYY").isSameOrAfter(s);
    });
    rows = newData;
  }

  if (endDate && !startDate) {
    const newData = rows.filter((v, i) => {
      const e = moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD");

      return moment(v.date, "DD/MM/YYYY").isSameOrBefore(e);
    });
    rows = newData;
  }
  return rows;
}

export function filterRowsDocuments(datas, startDate, endDate, type) {
  let rows = datas;

  if (type) {
    const newData = rows.filter((v) => {
      return v.types === type;
    });
    rows = newData;
  }

  if (startDate && endDate) {
    const newData = rows.filter((v, i) => {
      const s = moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD");
      const e = moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD");
      return moment(v.date, "DD/MM/YYYY").isBetween(s, e, null, []);
    });
    rows = newData;
  }

  if (startDate && !endDate) {
    const newData = rows.filter((v, i) => {
      const s = moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD");
      return moment(v.date, "DD/MM/YYYY").isSameOrAfter(s);
    });
    rows = newData;
  }

  if (endDate && !startDate) {
    const newData = rows.filter((v, i) => {
      const e = moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD");

      return moment(v.date, "DD/MM/YYYY").isSameOrBefore(e);
    });
    rows = newData;
  }

  return rows;
}

export function dateFormatL(date) {
  return moment(date).format("L");
}
export function dateIsLower(date, standard) {
  return moment(date).transform("00:00:00.000") < addDays(standard)
    ? true
    : false;
}
export function dateVentesRetractation(date, standard) {
  return moment().transform("00:00:00.000") <
    moment(date).transform("00:00:00.000").add(standard, "days")
    ? true
    : false;
}

export function checkEnterKeyDown(e) {
  if (e.code === "Enter") e.preventDefault();
}

export function formatErrorPhoneNumber(value, forbidden) {
  if (value === forbidden) {
    return i18n.t("error.invalidPhone");
  }
  if (value?.length < 10) {
    return i18n.t("error.phoneLength");
  }
  if (/^(0)(6|7)\d{8}$/?.test(value) === false) {
    return i18n.t("error.mustBeMobilePhone");
  }
}

export function formatErrorPhoneNumberAlt(value, forbidden) {
  if (!!value) {
    if (value === forbidden) {
      return i18n.t("error.invalidPhone");
    }
    if (value?.length < 10) {
      return i18n.t("error.phoneLength");
    }
    if (/^(0)(1|2|3|4|5|6|7|9)\d{8}$/?.test(value) === false) {
      return i18n.t("error.mustBeMobilePhone2");
    }
  }
}

export function formatErrorNames(value) {
  if (/^[a-zA-ZÀ-ÿ-. ]*$/?.test(value) === false || value?.length === 0) {
    return i18n.t("error.name");
  }
}

export const autoCompleteAlea = () => {
  const crypto = window.crypto || window.msCrypto;
  var array = new Uint32Array(1);
  const valueAlea = crypto.getRandomValues(array);
  return valueAlea[0].toString();
};

export const replaceFirstNumbers = (value) => {
  if (!!value) {
    return value.replace(/\d/g, "");
  }
};

export const check0to100 = (value) => {
  if (value > 100 || value < 0) {
    return i18n.t("error.mustbe0to100");
  }
};

export function capitalizeFirstLetter(string) {
  return string?.charAt(0).toUpperCase() + string?.slice(1).toLowerCase();
}

export function add0IfNeededBefore(numb) {
  return ("0" + numb).slice(-2);
}

/**
 * @name trueOrFalseDo transforme value en booléen en renvoie une action dépendant de true ou false
 * @description Same as if/else
 */
export function trueOrFalseDo(value, actionTrue, actionFalse) {
  if (!!value === true) {
    return actionTrue();
  } else {
    return actionFalse();
  }
}

export function onlyNumbersAndLetters(str) {
  return /^[a-z A-Z0-9]+$/?.test(str);
}

export function internationalMobilePhoneToRegular(
  internationalCode,
  regularNumber,
  phoneNumber
) {
  return phoneNumber?.replace(internationalCode, regularNumber);
}

export function formatHPHC(str) {
  return str
    ?.replace("HC", "")
    ?.replace("(", "")
    ?.replace(")", "")
    ?.replace(";", " & ")
    ?.replace("-", " à ")
    ?.replace("-", " à ")
    ?.toLowerCase();
}

export function getSouscriptionsOuidList(products) {
  // récupérer les targetProductOuid uniques dans product => productRelationships[0]
  let ouids = products?.map((product) => {
    if (!!product?.productRelationships[0]?.targetProductOuid)
      return product?.productRelationships[0]?.targetProductOuid;
  });
  return _.uniq(_.compact(ouids));
}

export function regroupContracts(products, catalog) {
  // regroupe les products par l'ouid du maitre product (ELECTRICITE_SOUSCRIPTION)
  let ouidList = getSouscriptionsOuidList(products);
  //

  let catalogPlans = catalog?.filter((x) => x?.category?.name === "PLAN");

  let filledProducts = products?.map((product) => {
    return catalogPlans?.find((plan) => plan?.name === product?.name)
      ? {
        ...product,
        plan: catalogPlans?.find((plan) => plan?.name === product?.name)
      }
      : product;
  });


  //
  let sortedProducts = ouidList?.map((ouid, index) => {

    let souscriptionProdcuts = filledProducts?.find(
      (product) => product.ouid === ouid
    );
    if(souscriptionProdcuts === undefined){
      return undefined;
    }
    if (souscriptionProdcuts) {
      let souscriptionProductOffering = catalog.find(
        (po) => po.ouid === souscriptionProdcuts.productOfferingOuid
      );

      souscriptionProdcuts.catalogRef = souscriptionProductOffering;
      let contractCode = souscriptionProdcuts?.productSerialNumber;
      let output = {
        ouid: ouid,
        souscription: souscriptionProdcuts,
        products: filledProducts?.filter(
          (product) =>
            product?.productRelationships[0]?.targetProductOuid === ouid
        ),
        contractCode: contractCode,
        selected: index === 0 ? true : false,
      };
      return output;
    }
  });

  sortedProducts = sortedProducts.filter((x) => x !== undefined);

  return sortedProducts;
}

// String to proper case style
export function split(str) {
  return str
    .split(" ")
    .map((w) => w[0].toUpperCase() + w.substring(1).toLowerCase())
    .join(" ");
}

export const typePayment = (stt) => {
  if (stt === "DEBIT") return "Paiement";
  if (stt === "CREDIT") return "Remboursement";
  const nexStt = split(stt);
  return nexStt;
};

export const statutPayment = (statut) => {
  const cancel = "Annulé";
  const wait = "En attente";
  const done = "Payé";
  const reject = "Rejeté";

  switch (statut) {
    case "CANCELLED":
      return cancel;
    case "SCHEDULED":
      return wait;
    case "TO_RETRY":
      return wait;
    case "TO_SEND":
      return wait;
    case "TO_SEND_BATCH":
      return wait;
    case "SEND_ERROR":
      return wait;
    case "WAIT_RESPONSE":
      return wait;
    case "DONE":
      return done;
    case "REJECTED":
      return reject;
    case "PENDING_REJECTED":
      return wait;
    case "LOCKED":
      return wait;

    default:
      return "";
  }
};

export const statutPaymentIcon = (statut) => {
  const error = "Error";
  const wait = "Wait";
  const done = "Active";

  switch (statut) {
    case "Annulé":
      return error;
    case "En attente":
      return wait;
    case "Payé":
      return done;
    case "Rejeté":
      return error;

    default:
      return "";
  }
};

// Maybe change string when there's a endDate souscription
export const statutContrat = (status) => {
  const resi = <span style={{ color: "red" }}>Ce contrat est résilié</span>;
  const wait = "Ce contrat est attente";
  const created = "Ce contrat est en cours d'activation.";

  switch (status) {
    case "CREATED":
      return created;

    case "PENDING_ACTIVE":
      return created;

    case "SUSPENDED":
      return wait;

    case "PENDING_REACTIVATED":
      return wait;

    case "TERMINATED":
      return resi;

    default:
      return <></>;
  }
};

export const statutContratWarningBloc = (status) => {
  const resi = "Votre contrat est résilié.";
  const wait = "Votre contrat est en attente.";
  const created = "Votre contrat est en cours d'activation.";

  switch (status) {
    case "ABORTED":
      return;

    case "ACTIVE":
      return <></>;

    case "CANCELLED":
      return;

    case "CREATED":
      return created;

    case "PENDING_ABORTED":
      return;

    case "PENDING_ACTIVE":
      return created;

    case "PENDING_TERMINATED":
      return;

    case "PENDING_SUSPENDED":
      return;

    case "SUSPENDED":
      return wait;

    case "PENDING_REACTIVATED":
      return wait;

    case "TERMINATED":
      return resi;

    default:
      return <></>;
  }
};

export const strongPasswordLabel = (result) => {
  switch (result) {
    case 0:
      return "Faible";
    case 1:
      return "Faible";
    case 2:
      return "Correct";
    case 3:
      return "Correct";
    case 4:
      return "Fort";
    default:
      return "Faible";
  }
};

export function replacePointByCommaInDecimals(str) {
  return str?.toString()?.replace(".", ",");
}

export function getSumPricesHCHP(datas) {
  const sum = datas.reduce((prev, curr, index, array) => prev + curr.taxExcludedRatingAmount, 0)
  return sum
}

export const rulesStep3 = {
  min: {
    rule: (x) =>
      x === x?.toLowerCase() && isNaN(x * 1) && /[a-zA-ZÀ-ÿ]/?.test(x),
  },
  maj: {
    rule: (x) =>
      x === x?.toUpperCase() && isNaN(x * 1) && /[a-zA-ZÀ-ÿ]/?.test(x),
  },
  number: { rule: (x) => !isNaN(x * 1) },
  spec: {
    rule: (x) =>
      /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/?.test(x) &&
      !/[a-zA-ZÀ-ÿ]/?.test(x),
  },
  minlength: { rule: (x) => (x?.length >= 8 ? true : false) },
  same: { rule: (x, y) => x === y },
};
